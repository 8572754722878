import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

/* eslint-disable react/prop-types */
function ElementAlert({ variant, title, description, ...rest }) {
  const [show, setShow] = useState(true);
  const toggle = () => setShow((prev) => !prev);

  return (
    show && (
      <Alert variant={variant} onClose={toggle} dismissible {...rest}>
        {title && <Alert.Heading>{title}</Alert.Heading>}
        {description && <p className="mb-0">{description}</p>}
      </Alert>
    )
  );
}

export default ElementAlert;
