import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function WebFormWrapper({ tagline, title, subtitle, data }) {
  const [active, setActive] = useState(data[0].id);
  const handleClick = (event) => {
    setActive(event.target.value);
  };

  return (
    <Container fluid className="bg-shapes-black-mountain px-0 pb-6 pt-8">
      <Container>
        <Row>
          <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
            <Col xs={12}>
              {tagline && <h2 className="font-weight-normal text-size-lg text-uppercase text-center text-white">{tagline}</h2>}
              {title && <div className="h2 text-center text-white">{title}</div>}
              {subtitle && (
                <div className="font-weight-normal text-size-lg text-center text-white" dangerouslySetInnerHTML={{ __html: subtitle }} />
              )}
            </Col>
            <Col xs={12}>
              <Row className="justify-content-center">
                {data
                  .filter((t) => t.tab)
                  .map((e) => (
                    <Col xs="auto" key={e.id} className="pt-5">
                      <button
                        type="button"
                        onClick={handleClick}
                        onFocus={handleClick}
                        value={e.id}
                        style={{
                          appearance: 'none',
                          backgroundColor: 'transparent',
                          border: '0',
                          outline: 'none',
                        }}
                        className={['btn-link-secondary', active === e.id ? 'active' : null].join(' ').trim()}>
                        {e.tab}
                      </button>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Col>
        </Row>
      </Container>
      {data.map((e) => (
        <Container
          key={e.id}
          id={e.id}
          fluid
          className={['px-0', active === e.id ? 'active animate_base animate_fadeInUp' : 'd-none'].join(' ')}>
          {e.component}
        </Container>
      ))}
    </Container>
  );
}

WebFormWrapper.propTypes = {
  title: PropTypes.string,
  tagline: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node,
      tab: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
};

WebFormWrapper.defaultProps = {
  title: null,
  tagline: null,
  subtitle: null,
};

export default WebFormWrapper;
