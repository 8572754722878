/* eslint-disable react/prop-types */
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

/**
 * This component should be placed to pages where there are forms.
 * (Wrapping the whole page is important.)
 */
function WebFormContext({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}>
        {children}
      </GoogleReCaptchaProvider>
    </QueryClientProvider>
  );
}

export default WebFormContext;
